import PropTypes from "prop-types"
import React, { useState } from "react"
import {Link, Trans} from 'gatsby-plugin-react-i18next';


const Header = ({ siteTitle }) => {
  const [toggle, setToggle] = useState(false);
  return (
    <header className="header">
      <div className={toggle? "header__links active": "header__links" }>
        <span className="header__link"><Link to="/#story" onClick={() => setToggle(false)}><Trans>Our Story</Trans></Link></span>
        <span className="header__link"><Link to="/#menu" onClick={() => setToggle(false)}><Trans>Menu</Trans></Link></span>
        <span className="header__link"><Link to="/#contact" onClick={() => setToggle(false)}><Trans>Visit Us</Trans></Link></span>
        <span className="header__link"><Link to="/#call" onClick={() => setToggle(false)}><Trans>Call Us</Trans></Link></span>
      </div>
      <div className={toggle? "header__toggle open": "header__toggle" } onClick={() => setToggle(!toggle)} >
        <div className="header__nav"></div>
      </div>
    </header>
  )
  
}
  

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
